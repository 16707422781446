nav {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  $background-color: rgba(255, 255, 255, 0.9);
  background-color: $background-color;

  z-index: 999;

  a {
    display: inline-block;
    text-decoration: none;
    padding: 20px 15px;
    $link-color: #444;
    color: $link-color;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.9em;
    font-weight: bold;

    $border-radius-size: 5px;
    &:first-child {
      border-top-left-radius: $border-radius-size;
      border-bottom-left-radius: $border-radius-size;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &.active {
      background-color: $link-color;
      color: $background-color;
    }

    &:hover {
      background-color: $link-color;
      color: $background-color;
      text-decoration: underline;
    }
  }
}
