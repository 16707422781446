.sidebar {
  width: 250px;
  padding: 70px 0 0 0;
  margin: 0 auto;
  flex-shrink: 0;

  @media #{$desktop} {
    margin: 0;
    padding: 0 0 0 70px;
  }

  .author {
    text-align: center;
    p {
      padding: 0;
      margin: 0;
    }
    img {
      width: 200px;
      height: 270px;
      border-radius: 100px;
      margin: 0 0 10px 0;
    }
  }

  .social-icons {
    text-align: center;
    margin: 10px 0 0 0;

    a {
      padding: 3px;
      text-decoration: none;

      &:hover svg {
        fill: #000;
      }

      width: 25px;
      height: 25px;

      svg {
        width: 25px;
        height: 25px;
        fill: $light-grey;
      }
    }
  }
}

.sidebar.push {
  @media #{$desktop} {
    padding-top: 72px;
  }
}
